/* .navbar-light .navbar-nav .nav-link {
    color: black;
    font-weight: bold;
    padding-right: 10px;
  } */

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-toggler {
  color: #FFFFFF;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: orange;
  text-decoration-line: none;
}

.add-shadow {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px;
}

#zmmtg-root {
  display: none;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  background-image: url(../images/background.jpg);
  background-repeat: repeat;
  background-attachment: scroll;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

p {
  margin-bottom: 0;
  text-align: start;
  unicode-bidi: plaintext;
}

::selection {
  background-color: #e77813;
  color: #FFFFFF;
}