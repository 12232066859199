* {
  font-family: 'tajawal';
}

@font-face {
  font-family: 'tajawal';
  src: url(../fonts/Tajawal-Medium.ttf);
  font-style: normal;
}

.MuiTypography-body1 {
  font-family: 'tajawal';
}